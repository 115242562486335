import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch!`}</h2>
    <p>{`Say hi on `}<a parentName="p" {...{
        "href": "https://twitter.com/atiqaikram"
      }}>{`Twitter`}</a>{`, or find me on `}<a parentName="p" {...{
        "href": "https://github.com/atiqaikram"
      }}>{`Github`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/atiqa-ikram-316261176/"
      }}>{`LinkedIn`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      